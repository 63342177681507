import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/docs/src/components/layouts/documentation.js";
import SEO from '../../components/seo';
import { Card } from 'godspeed';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Card" description="hello description" mdxType="SEO" />
    <h1></h1>
    <h1>{`Card`}</h1>
    <h2>{`Cards are useful for giving elevation to the body or separating content.`}</h2>
    <br />
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import { Card } from 'godspeed'
`}</code></pre>
    <br />
    <h1>{`Default Cards`}</h1>
    <h2>{`This component expects children.`}</h2>
    <h2>{`These come out of the box, light with dark text.`}</h2>
    <p>{`You can pass the "bg" prop to change the background of the card.`}</p>
    <p>{`You can pass the "color" prop to change the font color of the card.`}</p>
    <main>
  <section>
    <Card className="card" mdxType="Card">
      <h1 style={{}}>Card Title</h1>
      <p>Some placeholder content</p>
    </Card>
  </section>
  <section>
    <Card className="card" bg="rgb(17, 17, 17)" color="white" mdxType="Card">
      <h1 style={{}}>Card Title</h1>
      <p>Some placeholder content</p>
    </Card>
  </section>
    </main>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Card className="card">
  <h1>Card Title</h1>
  <p>Some placeholder content</p>
</Card>
<Card className="card" bg="black" color="white">
  <h1>Card Title</h1>
  <p>Some placeholder content</p>
</Card>
`}</code></pre>
    <h1>{`Customized Cards`}</h1>
    <p>{`You can pass the "borderBg" prop to change the border color.`}</p>
    <p>{`You can pass the "padding" prop to change the padding inside the card.`}</p>
    <p>{`You can pass the "radius" prop to change the border radius of the card.`}</p>
    <main>
  <section>
    <Card className="item card" borderBg="steelblue" mdxType="Card">
        <h1 style={{}}>Card Title</h1>
        <p>Custom border color</p>
    </Card>
  </section>
  <section>
    <Card className="item card" padding="20px" mdxType="Card">
        <h1 style={{}}>Card Title</h1>
        <p>Custom padding inside of card</p>
    </Card>
  </section>
  <section>
    <Card className="item card" radius="15px" mdxType="Card">
        <h1 style={{}}>Card Title</h1>
        <p>Custom border radius on card</p>
    </Card>
  </section>
    </main>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Card className="item card" borderBg="steelblue">
    <h1>Card Title</h1>
    <p>Custom border color</p>
</Card>
<Card className="item card" padding="20px">
    <h1>Card Title</h1>
    <p>Custom padding inside of card</p>
</Card>
<Card className="item card" radius="15px">
    <h1>Card Title</h1>
    <p>Custom border radius on card</p>
</Card>
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      